import React from "react";
import { theme } from "../../../themes/main";
import Label from "./Label";

type Props = {
  label: string;
  number?: boolean;
  inputStyle?: React.CSSProperties;
  onChangeText?: (value: string) => void;
} & React.InputHTMLAttributes<HTMLInputElement>;

const TextInput: React.FC<Props> = ({
  inputStyle,
  label,
  number = false,
  onChange,
  onChangeText,
  value,
  ...inputProps
}) => (
  <div
    style={{
      margin: ".8rem 0",
    }}
  >
    {!!label && <Label>{label}</Label>}
    <input
      type={number ? "number" : "text"}
      value={value}
      onChange={(e) => {
        if (!!onChangeText) onChangeText(e.target.value);
        if (!!onChange) onChange(e);
      }}
      style={{
        borderRadius: 4,
        border: `2px solid ${theme.colors.secondary}`,
        fontSize: "1.2rem",
        width: "100%",
        padding: ".4rem",
        backgroundColor: "#f7f7f7",
        ...inputStyle,
      }}
      {...inputProps}
    />
  </div>
);

export default TextInput;
