import React from "react";
import { useHistory } from "react-router-dom";

import { bingKey } from "../../config";
import { sendEvent } from "../../lib/gtag";
import AppContext from "../AppContext";
import {
  availableAirportCategories,
  readMapState,
  saveMapState,
} from "./functions";

const bingCallback = "bingLoaded";
const airportColors = availableAirportCategories
  .map(({ color }) => color)
  .reverse();

declare global {
  interface Window {
    [bingCallback]: any;
    Microsoft: {
      Maps: {
        Map: {};
        MapTypeId: {};
        PushPin: {};
      };
    };
  }
}

type Props = {
  airports: Array<Airport>;
  setMapBoundaries: (boundaries: MapBounds) => void;
  setMapLoaded: (isLoaded: boolean) => void;
};

const BingMap: React.FC<Props> = ({
  airports,
  setMapBoundaries,
  setMapLoaded,
}) => {
  const { mapView } = React.useContext(AppContext);

  const history = useHistory();
  const [scriptLoaded, setScriptLoaded] = React.useState(false);
  const mapContainerRef = React.useRef<HTMLDivElement>(null);
  const mapsRef = React.useRef<any>(null);
  const mapInstanceRef = React.useRef<any>(null);

  React.useEffect(() => {
    if (!!mapView && !!mapsRef.current) {
      const { Location } = mapsRef.current;
      const mapInstance = mapInstanceRef.current;

      mapInstance.setView({
        center: new Location(mapView.lat, mapView.lon),
        zoom: mapView.zoom,
      });
    }
  }, [mapView]);

  React.useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.defer = true;
    script.src = `https://www.bing.com/api/maps/mapcontrol?callback=${bingCallback}`;

    window[bingCallback] = () => {
      if (!!window.Microsoft.Maps) {
        setScriptLoaded(true);
        mapsRef.current = window.Microsoft.Maps;
      }
    };

    document.body.appendChild(script);

    return () => {
      setScriptLoaded(false);
      mapsRef.current = null;
      mapInstanceRef.current = null;
      script.remove();
    };
  }, []);

  React.useEffect(() => {
    if (scriptLoaded && !!mapsRef.current) {
      const { Map, MapTypeId, Events, Layer } = mapsRef.current;
      const mapState = readMapState();
      const mapInstance = new Map("#mapWrapper", {
        credentials: bingKey,
        mapTypeId: MapTypeId.aerial,
        zoom: mapState?.zoom,
        center: mapState?.center,
      });
      mapInstanceRef.current = mapInstance;

      const moveHandler = Events.addHandler(
        mapInstance,
        "viewchangeend",
        (event: any) => {
          setMapBoundaries(event.target.getBounds());
          saveMapState(event.target.getCenter(), event.target.getZoom());
          sendEvent("map_move", {
            event_category: "map",
            event_label: "map moved",
            value: `${event.target.getCenter().latitude},${
              event.target.getCenter().longitude
            },${event.target.getZoom()}`,
          });
        }
      );
      setMapLoaded(true);

      const airportsLayer = new Layer("airports");

      const layerClickHandler = Events.addHandler(
        airportsLayer,
        "click",
        (event: any) => {
          const airport: Airport = event.target.metadata;

          sendEvent("map_click", {
            event_category: "map",
            event_label: "airport click",
            value: airport.name,
          });

          history.push(`/airport/${airport.ident}`);
        }
      );

      mapInstance.layers.insert(airportsLayer);

      return () => {
        setMapLoaded(false);
        Events.removeHandler(moveHandler);
        Events.removeHandler(layerClickHandler);
      };
    }
  }, [scriptLoaded, setMapBoundaries, setMapLoaded, history]);

  React.useEffect(() => {
    if (scriptLoaded && mapInstanceRef.current) {
      const mapInstance = mapInstanceRef.current;

      const airportsLayer = mapInstance.layers.find(
        (layer: any) => layer.getId() === "airports"
      );

      airportsLayer.clear();
      airports.forEach((airport) => {
        const location = new mapsRef.current.Location(
          airport.laty,
          airport.lonx
        );

        const pin = new mapsRef.current.Pushpin(location, {
          title: `${airport.name} - ${airport.ident}`,
          subTitle: `alt: ${airport.altitude}ft, len: ${airport.longest_runway_length}ft`,
          text: airport.rating,
          cursor: "pointer",
          enableHoverStyle: true,
          color: airportColors[airport.rating],
        });

        pin.metadata = airport;
        airportsLayer.add(pin);
      });
    }
  }, [airports, scriptLoaded]);

  return (
    <div
      ref={mapContainerRef}
      id="mapWrapper"
      style={{
        position: "relative",
        width: "100%",
      }}
    />
  );
};

export default BingMap;
