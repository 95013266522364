import React from "react";

const Label: React.FC = ({ children }) => (
  <label
    style={{
      display: "block",
      fontWeight: 500,
      margin: ".5rem 0",
      fontSize: '1.1rem'
    }}
  >
    {children}
  </label>
);

export default Label;
