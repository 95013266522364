import React from "react";
import Slider from "react-input-slider";

import { theme } from "../../../themes/main";
import Label from "./Label";

type Props = {
  label?: string;
  maxValue: string;
  minValue: string;
  onChange: (value: string) => void;
  step?: number;
  value: string;
};

const SliderInput: React.FC<Props> = ({
  label,
  minValue,
  maxValue,
  onChange,
  step = 10,
  value,
}) => {
  const SliderState = React.useMemo(() => {
    return {
      x: parseInt(value),
      xmin: parseInt(minValue),
      xmax: parseInt(maxValue),
      xstep: step,
    };
  }, [minValue, maxValue, value, step]);

  const handleChange = React.useCallback(
    ({ x }: { x: number; y: number }) => {
      onChange(x.toString());
    },
    [onChange]
  );

  return (
    <div
      style={{
        margin: "1rem 0",
      }}
    >
      {!!label && <Label>{label}</Label>}
      <div style={{
        padding: "0 .5rem"
      }}>
        <Slider
          {...SliderState}
          onChange={handleChange}
          styles={{
            track: {
              backgroundColor: "white",
              width: "100%",
            },
            active: {
              backgroundColor: theme.colors.secondary,
            },
            thumb: {
              backgroundColor: "white",
              border: "2px solid " + theme.colors.secondary,
              width: 25,
              height: 25,
            },
            disabled: {
              opacity: 0.5,
            },
          }}
        />
      </div>
    </div>
  );
};

export default SliderInput;
