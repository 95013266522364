import React from "react";

type Props = {
  checked: boolean;
  label: string;
  onChange: () => void;
};

const CheckBox: React.FC<Props> = ({ checked, label, onChange }) => (
  <label
    style={{
      display: "flex",
      alignItems: "center",
      margin: ".5rem 0",
      fontSize: "1.2rem",
    }}
  >
    <input
      type="checkbox"
      checked={checked}
      onChange={onChange}
      style={{
        width: "1.2rem",
        height: "1.2rem",
        marginRight: ".5rem",
      }}
    />{" "}
    {label}
  </label>
);

export default CheckBox;
