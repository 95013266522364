import React from "react";
import { useHistory } from "react-router-dom";

import { searchText } from "../../api/maps";
import { sendEvent } from "../../lib/gtag";
import { debounce } from "../../lib/utils";
import { theme } from "../../themes/main";
import TextInput from "../common/inputs/TextInput";

const SearchSuggestions: React.FC = () => {
  const history = useHistory();
  const [isFocused, setIsFocused] = React.useState(false);
  const [queryText, setQueryText] = React.useState("");
  const [suggestions, setSuggestions] = React.useState<Array<Airport>>([]);

  React.useEffect(
    () =>
      debounce(() => {
        if (!!queryText) {
          sendEvent("set_filter", {
            event_category: "filter",
            event_label: "textSearch",
            value: queryText,
          });
          searchText(queryText).then((res) => {
            if (res?.data) {
              setSuggestions(res.data);
            }
          });
        }
      }),
    [queryText]
  );

  return (
    <div>
      <TextInput
        label="Search for an airport"
        placeholder="Airport Ident, name or city"
        autoComplete="off"
        value={queryText}
        onChangeText={(value: string) => setQueryText(value)}
        onFocus={() => setIsFocused(true)}
        inputStyle={{
          padding: ".6rem .6rem",
          fontSize: "1.3rem",
        }}
      />
      {isFocused && !!queryText && (
        <div
          style={{
            backgroundColor: theme.colors.primaryDark,
            boxShadow: "rgb(3, 3, 3) 0px 0px 5px",
          }}
        >
          <button
            onClick={() => setIsFocused(false)}
            style={{ width: "100%", padding: ".5rem", fontWeight: 600 }}
          >
            CLOSE
          </button>
          {suggestions.map((airport, i) => (
            <div
              style={{
                margin: "0.2rem",
                padding: "0.5rem",
                borderBottom: `1px solid ${
                  i < suggestions.length - 1
                    ? theme.colors.secondary
                    : "transparent"
                }`,
                cursor: "pointer",
              }}
              key={airport.ident}
              onClick={() => {
                history.push(`/airport/${airport.ident}`);
                setIsFocused(false);
              }}
            >
              <p>
                <strong style={{ fontWeight: 600 }}>{airport.ident}</strong>
                {" - "}
                {airport.name}
              </p>
              <p>{airport.city}</p>
            </div>
          ))}
          {suggestions.length === 0 && (
            <p
              style={{
                textAlign: "center",
                fontStyle: "italic",
                fontSize: "1.4rem",
                padding: ".5rem",
              }}
            >
              No results
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default SearchSuggestions;
