import React from "react";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";

import { areBoundsInside, readFilterState, saveFilterState } from "./functions";
import { searchBox } from "../../api/maps";
import BingMap from "./BingMap";
import Filters from "./Filters";
import { theme } from "../../themes/main"; 
import styled from "styled-components";

const Aside = styled.aside<{ showFilters: boolean }>`
  position: fixed;
  z-index: 150;
  bottom: 0;
  top: 50px;
  background-color: ${({ theme }) => theme.colors.primary};
  box-shadow: rgb(3, 3, 3) 5px 0px 10px;
  width: ${({ showFilters }) => (showFilters ? "400px" : "0px")};
  max-width: 90vw;
  transition: 200ms;
`;

const FiltersWrapper = styled.div`
  width: 400px;
  padding: 1rem;
  overflow: auto;
  height: 100%;
  max-width: 90vw;
`;

const FiltersButton = styled.button`
  position: absolute;
  box-shadow: rgb(3, 3, 3) 5px 0px 5px -1px;
  top: 5px;
  right: -30px;
  width: 30px;
  height: 40px;
  outline: none;
  cursor: pointer;
  border-bottom-right-radius: 5;
  border-top-right-radius: 5;
  background-color: ${({ theme }) => theme.colors.primary};
`;

const Map: React.FC = () => {
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [showFilters, setShowFilters] = React.useState(false);
  const [filterState, setFilterState] = React.useState<Filter>({
    ils: false,
    lights: false,
    hardSurface: false,
    minLength: "0",
    ...readFilterState(),
  });

  const airportsReducer = (
    state: {
      airports: Array<Airport>;
      airportCap?: number;
      activeBoundaries?: MapBounds;
    },
    action:
      | { type: "boundaryUpdate"; boundaries: MapBounds }
      | { type: "apiResponse"; response: any; newBoundaries?: MapBounds }
  ) => {
    switch (action.type) {
      case "boundaryUpdate":
        if (
          (!!state.airportCap && state.airports.length >= state.airportCap) ||
          !areBoundsInside(action.boundaries, state.activeBoundaries)
        ) {
          return {
            ...state,
            activeBoundaries: action.boundaries,
          };
        } else {
          return state;
        }

      case "apiResponse":
        return {
          ...state,
          airports: action.response.data,
          airportCap: action.response.meta.result_cap,
        };
    }
  };

  const [airportsState, airportsDispatch] = React.useReducer(airportsReducer, {
    airports: [],
  });

  React.useEffect(() => {
    if (!isLoaded || !airportsState.activeBoundaries) return;

    searchBox(airportsState.activeBoundaries?.bounds, filterState).then(
      (res) => {
        if (!!res?.data) {
          airportsDispatch({
            type: "apiResponse",
            response: res,
          });
        }
      }
    );
  }, [airportsState.activeBoundaries, filterState, isLoaded]);

  const handleMapboundariesChange = React.useCallback(
    (boundaries: MapBounds) => {
      airportsDispatch({
        type: "boundaryUpdate",
        boundaries,
      });
    },
    []
  );

  React.useEffect(() => {
    saveFilterState(filterState);
  }, [filterState]);

  return (
    <>
      <BingMap
        airports={airportsState.airports}
        setMapBoundaries={handleMapboundariesChange}
        setMapLoaded={setIsLoaded}
      />
      <Aside showFilters={showFilters}>
        <div style={{ overflow: "hidden", height: "100%" }}>
          <FiltersWrapper>
            <Filters
              filterState={filterState}
              setFilterState={setFilterState}
              airportsLoaded={airportsState.airports.length}
              airportCap={airportsState.airportCap}
            />
          </FiltersWrapper>
        </div>
        <FiltersButton onClick={() => setShowFilters(!showFilters)}>
          {showFilters ? (
            <MdChevronLeft
              size={25}
              color={theme.colors.primaryText}
              style={{
                display: "block",
                margin: "auto",
              }}
            />
          ) : (
            <MdChevronRight
              size={25}
              color={theme.colors.primaryText}
              style={{
                display: "block",
                margin: "auto",
              }}
            />
          )}
        </FiltersButton>
      </Aside>
    </>
  );
};

export default Map;
