import React from "react";
import { Link } from "react-router-dom";
import styled, { useTheme } from "styled-components";

import { ReactComponent as Logo } from "../assets/images/logo.svg";

const Wrapper = styled.div`
  margin-bottom: 50px;
`;

const Header = styled.header`
  position: fixed;
  height: 50px;
  z-index: 150;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.primaryDark};
  border-bottom: 2px solid ${({ theme }) => theme.colors.secondary};
`;

const InnerHeader = styled.header`
  padding: 0 1.4rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  color: ${({ theme }) => theme.colors.primaryText};
`;

const HeaderBar: React.FC = () => {
  const theme: any = useTheme();

  return (
    <Wrapper>
      <Header>
        <InnerHeader>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Link to="/">
              <Logo
                fill={theme.colors.primaryText}
                height={20}
                width={20}
                style={{ marginRight: 10 }}
              />
            </Link>
            <h1
              style={{
                fontSize: 20,
                fontWeight: 700,
              }}
            >
              <Link to="/" style={{ color: "inherit", textDecoration: "none" }}>
                MSFS Airports
              </Link>
            </h1>
          </div>

          <Link to="/about" style={{ color: "inherit" }}>
            About
          </Link>
        </InnerHeader>
      </Header>
    </Wrapper>
  );
};

export default HeaderBar;
