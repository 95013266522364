import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import styled from "styled-components";
import { MdArrowDownward, MdClose, MdZoomIn } from "react-icons/md";

import { getAirportByIdent } from "../../api/maps";
import AppContext from "../AppContext";
import { sendEvent } from "../../lib/gtag";
import { theme } from "../../themes/main";

const BackDrop = styled.div`
  position: fixed;
  display: flex;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 200;
  background-color: #000b;
  flex-direction: column;
  align-items: center;
`;

const Window = styled.div`
  position: relative;
  background-color: ${theme.colors.primary};
  color: ${theme.colors.primaryText};
  z-index: 210;
  padding: 1.5rem;
  margin-top: 50px;
  margin-bottom: 50px;
  overflow: auto;
  width: 90%;
  max-width: 600px;
`;

const Button = styled.button`
  padding: 1rem;
  background-color: ${theme.colors.secondary};
  color: ${theme.colors.primaryText};
  margin: 0.5rem;
  font-weight: 600;
  vertical-align: middle;
`;

const Title = styled.h1`
  font-size: 2rem;
  margin-bottom: 2rem;
`;

const Detail = styled.p`
  margin-bottom: 0.6rem;
`;

const AirportPage: React.FC = () => {
  const { setMapView } = React.useContext(AppContext);
  const history = useHistory();
  const backDropRef = React.useRef(null);
  const { ident } = useParams<{ ident: string }>();
  const [pageState, setPageState] = React.useState<{
    status: string;
    airport?: Airport;
  }>({
    status: "pageLoaded",
  });

  React.useEffect(() => {
    setPageState({
      status: "loading",
    });

    getAirportByIdent(ident).then((res: { data?: Airport; meta: {} }) => {
      if (!!res.data)
        setPageState({
          status: "loaded",
          airport: res.data,
        });
      else
        setPageState({
          status: "notFound",
        });
    });
  }, [ident]);

  const surfaces = React.useMemo(() => {
    const results = [];

    if (!!pageState?.airport?.num_runway_end_ils) results.push("ILS");
    if (!!pageState?.airport?.num_runway_hard) results.push("HARD");
    if (!!pageState?.airport?.num_runway_soft) results.push("SOFT");
    if (!!pageState?.airport?.num_runway_light) results.push("LIGHTS");
    if (!!pageState?.airport?.num_runway_end_vasi) results.push("VASI");
    if (!!pageState?.airport?.num_runway_water) results.push("WATER");

    return results.join(" / ");
  }, [pageState.airport]);

  return (
    <BackDrop
      ref={backDropRef}
      onClick={(e) => {
        if (e.target === backDropRef.current) history.push("/");
      }}
    >
      <Window>
        {pageState.status === "loading" && (
          <>
            <Helmet>
              <title>{`${ident} | MSFS Airports`}</title>
            </Helmet>
            <p>loading airport...</p>
          </>
        )}
        {pageState.status === "notFound" && (
          <>
            <Helmet>
              <title>{`Airport not found (${ident}) | MSFS Airports`}</title>
            </Helmet>
            <p>Airport {ident} not found</p>
          </>
        )}
        {pageState.status === "loaded" && !!pageState.airport && (
          <>
            <Helmet>
              <title>{`${pageState.airport.name} (${pageState.airport.ident}) | MSFS Airports`}</title>
              <meta
                name="description"
                content={`Detailed informations about ${pageState.airport.ident} ${pageState.airport.name} airport in ${pageState.airport.city} from MSFS 2020`}
                data-react-helmet="true"
              />
            </Helmet>
            <Title>
              {pageState.airport.name} ({pageState.airport.ident})
            </Title>

            <Detail>City: {pageState.airport.city}</Detail>
            <Detail>Altitude: {pageState.airport.altitude}ft</Detail>
            <Detail>
              latitude: {pageState.airport.laty} Longitude:{" "}
              {pageState.airport.lonx}
            </Detail>
            <Detail>In game rating: {pageState.airport.rating}</Detail>
            <Detail>ATIS: {pageState.airport.atis_frequency ?? "-"}</Detail>
            <Detail>TOWER: {pageState.airport.tower_frequency ?? "-"}</Detail>
            <Detail>
              Magnetic Variation: {pageState.airport.mag_var ?? "-"}
            </Detail>
            <Detail>Runways: {pageState.airport.num_runways ?? "-"}</Detail>
            <Detail>Runway types: {surfaces}</Detail>

            <Detail style={{ textDecoration: "underline" }}>
              Longest runway Informations:
            </Detail>
            <div
              style={{
                borderLeft: `2px solid ${theme.colors.secondary}`,
                paddingLeft: "1rem",
                marginBottom: "1.5rem",
              }}
            >
              <Detail>
                length: {pageState.airport.longest_runway_length}ft
              </Detail>
              <Detail>width: {pageState.airport.longest_runway_width}ft</Detail>
              <Detail>
                heading: {pageState.airport.longest_runway_heading} deg
              </Detail>
            </div>
            <Button
              onClick={() => {
                history.push("/");
                sendEvent("click", {
                  event_category: "airport_page_buttons",
                  event_label: "Center map",
                  value: pageState.airport?.ident,
                });
                if (!!pageState.airport)
                  setMapView({
                    lat: pageState.airport?.laty,
                    lon: pageState.airport?.lonx,
                  });
              }}
            >
              <MdArrowDownward size="1rem" /> CENTER MAP
            </Button>
            <Button
              onClick={() => {
                history.push("/");
                sendEvent("click", {
                  event_category: "airport_page_buttons",
                  event_label: "Airport Zoom",
                  value: pageState.airport?.ident,
                });
                if (!!pageState.airport)
                  setMapView({
                    lat: pageState.airport.laty,
                    lon: pageState.airport.lonx,
                    zoom: 15,
                  });
              }}
            >
              <MdZoomIn size="1rem" /> ZOOM IN
            </Button>
          </>
        )}
        <button
          style={{
            position: "absolute",
            top: 5,
            right: 5,
            display: "block",
            backgroundColor: "transparent",
          }}
          onClick={() => history.push("/")}
        >
          <MdClose color={theme.colors.primaryText} size="2.2rem" />
        </button>
      </Window>
    </BackDrop>
  );
};

export default AirportPage;
