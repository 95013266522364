const palette = {
  primary: "#001f38",
  primaryText: "#fff",
  primaryDark: "#000109",
  secondary: "#007b7d"
};

export const theme = {
  colors: {
    ...palette,
  },
};


