import React from "react";

const AppContext = React.createContext<{
  mapView?: {
    lat: number;
    lon: number;
    zoom?: number;
  };
  setMapView: (mapView?: { lat: number; lon: number; zoom?: number }) => void;
}>({ setMapView: () => null });

const AppContextProvider: React.FC = ({ children }) => {
  const [mapView, setMapView] = React.useState<{
    lat: number;
    lon: number;
    zoom?: number;
  }>();

  return (
    <AppContext.Provider
      value={{
        mapView,
        setMapView,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppContext as default, AppContextProvider };
