import React from "react";
import { Helmet } from "react-helmet-async";
import { MdOpenInNew } from "react-icons/md";
import styled from "styled-components";
import { sendEvent } from "../../lib/gtag";

const Wrapper = styled.div`
  flex-grow: 1;
  padding: 15px;
`;
const Content = styled.div`
  max-width: 768px;
  margin: auto;
`;

const Title = styled.h1`
  font-size: 28px;
  font-weight: 600;
  margin: 5px 0 16px;
`;

const SectionTitle = styled.h2`
  font-size: 20px;
  font-weight: 600;
  margin: 20px 0 20px;
`;

const Paragraph = styled.p(
  ({ theme }) => `
  line-height: 1.2;
  margin:5px 0 16px;

  a {
    color:${theme.colors.secondary};
    transition: opacity 200ms;

    &:hover {
      opacity: .8;
    }
  }

  i {
    font-style: italic;
  }

  strong {
    font-weight: 600;
  }

  small {
    font-size: .6em
  }
`
);

const ExternalLink: React.FC<{ href: string }> = ({ href, children }) => (
  <a href={href} target="_blank" rel="noreferrer">
    {children} <MdOpenInNew size={12} style={{ verticalAlign: "middle" }} />
  </a>
);

const AboutPage: React.FC = () => {
  return (
    <Wrapper>
      <Helmet>
        <title>About | MSFS Airports</title>
        <meta
          name="description"
          content="About this website and other useful resources"
          data-react-helmet="true"
        />
      </Helmet>
      <Content>
        <Title>About MSFS Airports</Title>
        <Paragraph>
          I am a flight sim enthusiast and I've create this useful app to let
          people easily browse and get informations about <strong>all</strong>{" "}
          the airports available in{" "}
          <ExternalLink href="https://www.flightsimulator.com/">
            Microsoft Flight Simulator 2020
          </ExternalLink>{" "}
          (MSFS for short).
        </Paragraph>
        <Paragraph>
          MSFS Airports is hosted on Netlify (free plan) and it is using Bing
          Maps to reflect what you would see in the sim as closely as possible.
          I have extracted airports informations from the game using{" "}
          <ExternalLink href="https://albar965.github.io/littlenavmap.html">
            Little Navmap
          </ExternalLink>
          .
        </Paragraph>
        <Paragraph>
          I am also using Google Analytics because seeing how you folks are
          interacting with my creation gives me a reason to keep working on this
          little side project.{" "}
          <small>
            (and it also gives me bragging rights with friends and family)
          </small>
        </Paragraph>
        <Paragraph>
          If you have a bug, feature requests, airport info mismatch or anything
          else that you want to report feel free to create a new issue{" "}
          <ExternalLink href="https://github.com/Breincorporation/msfsairports-issues/issues">
            on this github repository
          </ExternalLink>
          .
        </Paragraph>
        <Paragraph>
          Lastly, if you feel like donating something to support this project:{" "}
        </Paragraph>
        <form
          action="https://www.paypal.com/donate"
          method="post"
          target="_top"
          style={{ display: "inline" }}
          onSubmit={() =>
            sendEvent("donate_button", {
              event_category: "donation",
              event_label: "clicked donate button",
              value: true,
            })
          }
        >
          <input type="hidden" name="business" value="ZJQ9T2834U6CG" />
          <input type="hidden" name="no_recurring" value="1" />
          <input
            type="hidden"
            name="item_name"
            value="Making the world of msfs more accessible"
          />
          <input type="hidden" name="currency_code" value="AUD" />
          <input
            type="image"
            src="https://www.paypalobjects.com/en_AU/i/btn/btn_donate_SM.gif"
            name="submit"
            title="PayPal - The safer, easier way to pay online!"
            alt="Donate with PayPal button"
          />
          <img
            alt=""
            src="https://www.paypal.com/en_AU/i/scr/pixel.gif"
            width="1"
            height="1"
          />
        </form>
        <SectionTitle>
          Other interesting resources and people I follow:
        </SectionTitle>
        <Paragraph>
          <strong>Neofly:</strong> this is a fantastic free app that somehow
          inspired me in creating this project, it pairs with the sim and allows
          you to manage your career as a pilot. Start with a small cessna and
          end up with multiple fbos and airlines around the world, check their{" "}
          <ExternalLink href="https://www.neofly.net/">website</ExternalLink>.
        </Paragraph>
        <Paragraph>
          <strong>Bill4LE:</strong> very chilled real life pilot and simmer, I
          love to hear him talking about aviation (real and simulated). Check
          his{" "}
          <ExternalLink href="https://www.youtube.com/c/Bill4LE">
            youtube
          </ExternalLink>{" "}
          and{" "}
          <ExternalLink href="https://www.twitch.tv/bill4le">
            twitch
          </ExternalLink>{" "}
          channels.
        </Paragraph>
        <Paragraph>
          <strong>Missionary Bush Pilot:</strong> a real life pilot that flies
          all around Papua new Guinea. I love to follow his tracks in the sim.
          Check his{" "}
          <ExternalLink href="https://www.youtube.com/user/NTMApilot">
            youtube
          </ExternalLink>{" "}
          channel.
        </Paragraph>
        <Paragraph>
          <strong>Kiwi Mace:</strong> very chilled kiwi pilot and simmer, I love
          to hear his rambles, here's his{" "}
          <ExternalLink href="https://www.youtube.com/channel/UCe68woXZL8dMPhTS9SracFg">
            youtube
          </ExternalLink>{" "}
          channel.
        </Paragraph>
        <Paragraph>
          <strong>Obsidian Ant:</strong> I've been following this guy for ages,
          his{" "}
          <ExternalLink href="https://www.youtube.com/user/ObsidianAnt">
            youtube channel
          </ExternalLink>{" "}
          is all about making short videos that just get to the point focussed
          on flying games and sims, on earth and in space.
        </Paragraph>
      </Content>
    </Wrapper>
  );
};

export default AboutPage;
