export const sortAirportsByCategory = (airports: Array<Airport>) => {
  const airportsByCategory: { [key: number]: Array<Airport> } = {};

  airports.forEach((airport) => {
    Array.isArray(airportsByCategory[airport.rating])
      ? airportsByCategory[airport.rating].push(airport)
      : (airportsByCategory[airport.rating] = [airport]);
  });

  return airportsByCategory;
};

export const limitAirportsByCategory = (
  airports: Array<Airport>,
  maxAirports: number,
  enabledCategories: Array<number>
) => {
  const limitedAirports = [];
  const sortedAirports = sortAirportsByCategory(airports);
  const categories = Object.keys(sortedAirports)
    .map((key) => parseInt(key))
    .sort((key1, key2) => key2 - key1);

  for (
    let catIndex = 0;
    catIndex < categories.length && limitedAirports.length < maxAirports;
    catIndex++
  ) {
    if (enabledCategories.includes(categories[catIndex])) {
      for (
        let airportIndex = 0;
        airportIndex < sortedAirports[categories[catIndex]].length &&
        limitedAirports.length < maxAirports;
        airportIndex++
      )
        limitedAirports.push(
          sortedAirports[categories[catIndex]][airportIndex]
        );
    }
  }

  return limitedAirports;
};

export const shuffleArray = (array: Array<any>) => {
  var currentIndex = array.length,
    temporaryValue,
    randomIndex;

  while (0 !== currentIndex) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
};

export const availableAirportCategories = [
  { rating: 5, color: "#d50d0d" },
  { rating: 4, color: "#f40631" },
  { rating: 3, color: "#ff7e00" },
  { rating: 2, color: "#eddd0b" },
  { rating: 1, color: "#a2d224" },
  { rating: 0, color: "#19b622" },
];

export const saveMapState = (center: Point, zoom: number) => {
  localStorage.setItem("mapState", JSON.stringify({ center, zoom }));
};

export const readMapState: () => {
  center: Point;
  zoom: number;
} | null = () => {
  const mapState = localStorage.getItem("mapState");
  return !!mapState ? JSON.parse(mapState) : null;
};

export const saveFilterState = (filterState: Filter) => {
  localStorage.setItem("filterState", JSON.stringify(filterState));
};

export const readFilterState: () => Filter | null = () => {
  const filterState = localStorage.getItem("filterState");
  return !!filterState ? JSON.parse(filterState) : null;
};

export const areBoundsInside = (
  internalBounds?: MapBounds,
  externalBounds?: MapBounds
) => {
  if (!externalBounds || !internalBounds) return false;

  const newTopRight = {
    lat: internalBounds.bounds[0],
    lon: internalBounds.bounds[1],
  };

  const newBottomLeft = {
    lat: internalBounds.bounds[2],
    lon: internalBounds.bounds[3],
  };

  const oldTopRight = {
    lat: externalBounds.bounds[0],
    lon: externalBounds.bounds[1],
  };

  const oldBottomLeft = {
    lat: externalBounds.bounds[2],
    lon: externalBounds.bounds[3],
  };

  if (
    newTopRight.lat > oldTopRight.lat ||
    newBottomLeft.lat < oldBottomLeft.lat
  )
    return false;

  const isNewAcross = newTopRight.lon < newBottomLeft.lon;
  const isOldAcross = oldTopRight.lon < oldBottomLeft.lon;

  if (!isOldAcross && isNewAcross) return false;

  return isOldAcross === isNewAcross
    ? newTopRight.lon <= oldTopRight.lon &&
        newBottomLeft.lon >= oldBottomLeft.lon
    : (newTopRight.lon <= oldTopRight.lon ||
        newTopRight.lon > oldBottomLeft.lon) &&
        (newBottomLeft.lon < oldTopRight.lon ||
          newBottomLeft.lon >= oldBottomLeft.lon);
};
