import { AreaCoords } from "../@types/bingMaps/AreaCoords";

const APIURL = "/.netlify/functions";

const query = async (url: string, init: RequestInit = {}) => {
  const defaultInit: RequestInit = {
    method: "GET",
    headers: new Headers({}),
  };

  try {
    const result = await fetch(url, { ...defaultInit, ...init });

    if (result.ok) return result.json();
    else console.log("error", result);
  } catch (e) {
    console.log("exception", e);
    return [];
  }
};

export const getAirportByIdent = (airportIdent: string) => {
  return query(`${APIURL}/database?type=searchIdent&query=${airportIdent}`);
};

export const searchBox: (
  bounds?: AreaCoords,
  filters?: Filter
) => Promise<{ data: Array<Airport>; meta: { result_cap: number } }> = async (
  bounds,
  filters
) => {
  return !!bounds && !!filters
    ? query(
        `${APIURL}/database?type=searchBox&topRight=${bounds[0]},${bounds[1]}` +
          `&bottomLeft=${bounds[2]},${bounds[3]}&` +
          Object.keys(filters)
            .map((key) => `${key}=${filters[key]}`)
            .join("&")
      )
    : undefined;
};

export const searchText: (
  searchQuery: string
) => Promise<{ data: Array<any>; meta: any }> = async (searchQuery = "") => {
  return !!searchQuery
    ? query(`${APIURL}/database?type=searchText&query=${searchQuery}`)
    : undefined;
};
