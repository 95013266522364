import React from "react";

import CheckBox from "../common/inputs/CheckBox";
import Label from "../common/inputs/Label";
import SearchSuggestions from "./SearchSuggestions";
import { sendEvent } from "../../lib/gtag";
import SliderInput from "../common/inputs/SliderInput";
import { debounce } from "../../lib/utils";

type Props = {
  airportCap?: number;
  airportsLoaded: number;
  filterState: Filter;
  setFilterState: (filter: Filter) => void;
};

const Filters: React.FC<Props> = ({
  airportCap,
  airportsLoaded,
  filterState,
  setFilterState,
}) => {
  const [localFilterState, setLocalFilterState] = React.useState(filterState);
  const toggleFilter = (key: "lights" | "ils" | "hardSurface") => () => {
    sendEvent("set_filter", {
      event_category: "filter",
      event_label: key,
      value: !localFilterState[key],
    });

    setLocalFilterState({
      ...localFilterState,
      [key]: !localFilterState[key],
    });
  };

  React.useEffect(
    () =>
      debounce(() => {
        setFilterState(localFilterState);
      }),
    [localFilterState, setFilterState]
  );

  return (
    <div>
      <SearchSuggestions />
      <Label>Show only airports with:</Label>
      <CheckBox
        checked={localFilterState.ils}
        onChange={toggleFilter("ils")}
        label="ILS"
      />
      <CheckBox
        checked={localFilterState.lights}
        onChange={toggleFilter("lights")}
        label="Lights"
      />
      <CheckBox
        checked={localFilterState.hardSurface}
        onChange={toggleFilter("hardSurface")}
        label="Hard surface"
      />

      <SliderInput
        label={`Min length: ${localFilterState.minLength} ft`}
        minValue="0"
        maxValue="15000"
        step={500}
        value={localFilterState.minLength}
        onChange={(value) => {
          setLocalFilterState({
            ...localFilterState,
            minLength: value,
          });
        }}
      />
      <Label>{`Loaded ${airportsLoaded} (capped @ ${airportCap})`}</Label>
    </div>
  );
};

export default Filters;
