import React from "react";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import "./App.css";
import AboutPage from "./components/AboutPage";
import AirportPage from "./components/AirportPage";
import { AppContextProvider } from "./components/AppContext";
import HeaderBar from "./components/HeaderBar";
import MapPage from "./components/MapPage";
import { initTracking } from "./lib/gtag";
import { theme } from "./themes/main";

const App = () => {
  React.useEffect(initTracking, []);

  return (
    <HelmetProvider>
      <AppContextProvider>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Helmet>
              <title>MSFS Airports</title>
              <meta
                name="description"
                content="Easily browse and find informations on all the airports available in Microsoft Flight Simulator 2020"
                data-react-helmet="true"
              />
            </Helmet>
            <div
              className="App"
              style={{
                display: "flex",
                flexDirection: "column",
                minHeight: "100vh",
                backgroundColor: theme.colors.primaryDark,
                color: theme.colors.primaryText,
              }}
            >
              <HeaderBar />
              <div style={{ display: "flex", flex: 1 }}>
                <Switch>
                  <Route path="/about">
                    <AboutPage />
                  </Route>
                  <Route path="*">
                    <MapPage />
                  </Route>
                </Switch>
              </div>
              <Route path="/airport/:ident">
                <AirportPage />
              </Route>
            </div>
          </BrowserRouter>
        </ThemeProvider>
      </AppContextProvider>
    </HelmetProvider>
  );
};

export default App;
