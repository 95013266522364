import { gaTrackingCode, isProductionHost } from "../config";

declare global {
  interface Window {
    dataLayer: Array<any>;
  }
}

type gtagFn = (...args: any) => void;

export const gtag: gtagFn = function () {
  if (!gaTrackingCode) return;
  if (!window.dataLayer) window.dataLayer = [];
  window.dataLayer.push(arguments);
};

export const initTracking = () => {
  if (window.location.search.includes("notrack=true"))
    localStorage.setItem("notrack", "true");

  if (window.location.search.includes("notrack=false"))
    localStorage.removeItem("notrack");

  const enableTrack = isProductionHost && !localStorage.getItem("notrack");

  if (enableTrack && !!gaTrackingCode) {
    const gtagScript = document.createElement("script");
    gtagScript.async = true;
    gtagScript.src = `https://www.googletagmanager.com/gtag/js?id=${gaTrackingCode}`;

    gtagScript.addEventListener("load", () => {
      gtag("js", new Date());
      gtag("config", gaTrackingCode);
    });

    document.body.append(gtagScript);
  }
};

export const sendEvent = (
  eventName: string,
  params: { event_category: string; event_label: string; value: any }
) => {
  gtag("event", eventName, params);
};

export const sendPageView = (params?: {
  page_title?: string;
  page_location?: string;
  page_path?: string;
  send_to?: string;
}) => {
  gtag("event", "page_view", params);
};
